import * as React from "react";
import { PageProps } from "gatsby";
import Button, { ButtonProps } from "@/components/Button/button";
import "../../App.scss";
import "@/components/Button/button.scss";
import "@/components/ThemeLifeSpan/ThemeLifeSpan.scss";
import { Link } from "gatsby";
import Title from "@/components/Title/Title";
import Col from "@/components/Col/Col";
import CtaBar from "@/components/CtaBar/CtaBar";
import Section from "@/components/Section/Section";
import img404 from "@/img/404.svg";
import img404rest from "@/img/404rest.svg";

const NotFound: React.FC<PageProps> = () => (
  <main className="page__404">

  <Section className="hero404">
    <img className="hero404__img" src={img404} />
    {/*<img className="hero404__rest" src={img404rest} />*/}

    <div className="hero404__headline">
      <h3>
        It seems like there is no campaign to track. Please proceed to homepage.
      </h3>

    </div>

      <Button
        {...{
          variant: "blue",
          link: "/",
          text: "Go to homepage",
        }}
      />
  </Section>

  </main>
);

export default NotFound;
