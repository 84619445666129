import React, { ReactChild, ReactChildren } from "react";
import Col, { ColProps } from "../Col/Col";
import Title from '@/components/Title/Title';

interface SectionProps {
  className?: string
  id?: string
  children?: ReactChild | ReactChild[]
  colFlex: boolean
  reversed: boolean
}

const Section = ({
  className,
  id,
  children,
  colFlex,
  vCentered,
  reversed
  } : SectionProps) => {

    let colFlexFlag = "";
    let vCenteredFlag = "";
    let orderReversed = "";

    if (colFlex) {
      colFlexFlag = "colFlex";
    }

    if (vCentered) {
      vCenteredFlag = "vCentered";
    }

    if (reversed) {
      orderReversed = "reversed";
    }

  return (
    <section className={className} id={id}>
      <div className={`${colFlexFlag} ${vCenteredFlag} ${orderReversed} wrap`}>
        {children}
      </div>
    </section>
  )
};

export default Section;
